// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Adduomanddescription2 from "../../blocks/adduomanddescription2/src/Adduomanddescription2";
import Itempackandcomponentrmsitemintegration from "../../blocks/itempackandcomponentrmsitemintegration/src/Itempackandcomponentrmsitemintegration";
import Batchpickinguichangesforkorber from "../../blocks/batchpickinguichangesforkorber/src/Batchpickinguichangesforkorber";
import Itempackandcomponentsendordertokorber2 from "../../blocks/itempackandcomponentsendordertokorber2/src/Itempackandcomponentsendordertokorber2";
import Korberchangesforbatchpicking from "../../blocks/korberchangesforbatchpicking/src/Korberchangesforbatchpicking";
import Basickorberuiforenablinganddisablingkorberflag from "../../blocks/basickorberuiforenablinganddisablingkorberflag/src/Basickorberuiforenablinganddisablingkorberflag";
import Itempackandcomponentrmspackitemintegration2 from "../../blocks/itempackandcomponentrmspackitemintegration2/src/Itempackandcomponentrmspackitemintegration2";
import Basickorberintegrationcheckingpackedorders from "../../blocks/basickorberintegrationcheckingpackedorders/src/Basickorberintegrationcheckingpackedorders";
import Basickorberintegrationadditionalfields from "../../blocks/basickorberintegrationadditionalfields/src/Basickorberintegrationadditionalfields";
import Packandcomponentkorberconvflag2 from "../../blocks/packandcomponentkorberconvflag2/src/Packandcomponentkorberconvflag2";
import Addbatchsentfailreport from "../../blocks/addbatchsentfailreport/src/Addbatchsentfailreport";



const routeMap = {
Adduomanddescription2:{
 component:Adduomanddescription2,
path:"/Adduomanddescription2"},
Itempackandcomponentrmsitemintegration:{
 component:Itempackandcomponentrmsitemintegration,
path:"/Itempackandcomponentrmsitemintegration"},
Batchpickinguichangesforkorber:{
 component:Batchpickinguichangesforkorber,
path:"/Batchpickinguichangesforkorber"},
Itempackandcomponentsendordertokorber2:{
 component:Itempackandcomponentsendordertokorber2,
path:"/Itempackandcomponentsendordertokorber2"},
Korberchangesforbatchpicking:{
 component:Korberchangesforbatchpicking,
path:"/Korberchangesforbatchpicking"},
Basickorberuiforenablinganddisablingkorberflag:{
 component:Basickorberuiforenablinganddisablingkorberflag,
path:"/Basickorberuiforenablinganddisablingkorberflag"},
Itempackandcomponentrmspackitemintegration2:{
 component:Itempackandcomponentrmspackitemintegration2,
path:"/Itempackandcomponentrmspackitemintegration2"},
Basickorberintegrationcheckingpackedorders:{
 component:Basickorberintegrationcheckingpackedorders,
path:"/Basickorberintegrationcheckingpackedorders"},
Basickorberintegrationadditionalfields:{
 component:Basickorberintegrationadditionalfields,
path:"/Basickorberintegrationadditionalfields"},
Packandcomponentkorberconvflag2:{
 component:Packandcomponentkorberconvflag2,
path:"/Packandcomponentkorberconvflag2"},
Addbatchsentfailreport:{
 component:Addbatchsentfailreport,
path:"/Addbatchsentfailreport"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;